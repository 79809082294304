<template>
	<div class="home">
		<div class="datatitle">
			<div class="datatitle_name wirthbackgour">本月审批数据：</div>
			<div class="datatitle_item wirthbackgour">
				<div>政协办公室审批：</div>
				<div style="display: flex;align-items: center;margin-top:1vh;">
					<div style="font-size:0.16rem;font-weight: bold;">5</div>
					<div>/7</div>
				</div>
				<div class="jdt">
					<div style="background-color: #3894ff;">

					</div>
				</div>
			</div>
			<div class="datatitle_item wirthbackgour">
				<div>提案委员会审批：</div>
				<div style="display: flex;align-items: center;margin-top:1vh;">
					<div style="font-size:0.16rem;font-weight: bold;">8</div>
					<div>/12</div>
				</div>
				<div class="jdt">
					<div style="background-color: #ecaf0d;">

					</div>
				</div>
			</div>
			<div class="datatitle_item wirthbackgour">
				<div>立案会议：</div>
				<div style="display: flex;align-items: center;margin-top:1vh;">
					<div style="font-size:0.16rem;font-weight: bold;">3</div>
					<div>/4</div>
				</div>
				<div class="jdt">
					<div style="background-color: #0f8c2b;">

					</div>
				</div>
			</div>
			<div class="datatitle_item wirthbackgour">
				<div>承办单位接收：</div>
				<div style="display: flex;align-items: center;margin-top:1vh;">
					<div style="font-size:0.16rem;font-weight: bold;">4</div>
					<div>/7</div>
				</div>
				<div class="jdt">
					<div style="background-color: #900edb;">

					</div>
				</div>
			</div>
			<div class="datatitle_item wirthbackgour">
				<div>承办单位办结：</div>
				<div style="display: flex;align-items: center;margin-top:1vh;">
					<div style="font-size:0.16rem;font-weight: bold;">4</div>
					<div>/7</div>
				</div>
				<div class="jdt">
					<div style="background-color: #e32f16;">

					</div>
				</div>
			</div>
		</div>
		<div class="diyipai">
			<div class="item wirthbackgour">
				<div class="title_box">
					<div class="title" style="margin-right: 0.55rem;">委员提案分类</div>
					<div class="selectmouth">
						<div>月</div>
						<div>年</div>
					</div>
					<div class="block">
						<el-date-picker v-model="value1" type="date" size='small' placeholder="选择日期">
						</el-date-picker>
					</div>
				</div>
				<div style="font-size: 0.1rem;padding-left: 0.2rem;">单位：个</div>
				<div id="main" style="width:90%;height:70%;"></div>
			</div>
			<div class="item wirthbackgour">
				<div class="title_box">
					<div class="title" style="margin-right: 0.55rem;">委员提案量</div>
					<div class="selectmouth">
						<div>月</div>
						<div>年</div>
					</div>
					<div class="block">
						<el-date-picker v-model="value1" type="date" size='small' placeholder="选择日期">
						</el-date-picker>
					</div>
				</div>
				<div style="font-size: 0.1rem;padding-left: 0.2rem;">单位：个</div>
				<div id="main2" style="width:100%;height:70%;"></div>
			</div>
			<div class="item wirthbackgour">
				<div class="title_box" style="margin-bottom: 1vh;">
					<div class="title">各小组委员提案采纳率</div>
				</div>
				<div style="height: 1px;width: 94%;background-color: #d3d3d3;margin: auto;"></div>
				<div class="box_paiming">
					<div class="item_paim header_item">
						<div class="coulm">排名</div>
						<div class="coulm">小组</div>
						<div class="coulm">采纳率</div>
					</div>
					<div class="item_paim" v-for="(item,index) in paiming" :key="index">
						<div class="coulm">{{index+1}}</div>
						<div class="coulm">{{item.name}}</div>
						<div class="coulm" style="display: flex;align-items: center;">
							<div style="width: 0.5rem;">
								{{item.number}}%
							</div>

							<div :style="[item.style]">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="dierpai">
			<div class="item wirthbackgour">
				<div class="title_box">
					<div class="title" style="margin-right: 0.2rem;">各类委员提案采纳率</div>
					<div class="selectmouth">
						<div>月</div>
						<div>年</div>
					</div>
					<div class="block">
						<el-date-picker v-model="value1" type="date" size='small' placeholder="选择日期">
						</el-date-picker>
					</div>
				</div>
				<div id="main3" style="width:95%;height:78%;"></div>
			</div>
			<div class="item wirthbackgour">
				<div class="title_box">
					<div class="title" style="margin-right: 0.5rem;">个人提案数</div>
					<div class="selectmouth">
						<div>月</div>
						<div>年</div>
					</div>
					<div class="block">
						<el-select v-model="selectvalue" placeholder="请选择小组" size='small'>
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>
				<div id="main4" style="width:95%;height:78%;"></div>
			</div>
			<div class="item wirthbackgour">
				<div class="title_box">
					<div class="title" style="margin-right: 0.5rem;">各小组提案数</div>
					<div class="selectmouth">
						<div>月</div>
						<div>年</div>
					</div>
					<div class="block">
						<el-date-picker v-model="value1" type="date" size='small' placeholder="选择日期">
						</el-date-picker>
					</div>
				</div>
				<div style="font-size: 0.1rem;padding-left: 0.2rem;">单位：个</div>
				<div id="main5" style="width:95%;height:78%;"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts'
	export default {
		data() {
			return {
				value1: '',
				paiming: [
					{name: '第一小组',
						number: 99.5,
						style: {
							background: '#ff1600',
							borderRadius: '50px',
							width: (99.5 * 0.012) + 'rem',
							height: '2vh'
						}
					},
					{
						name: '第二小组',
						number: 99.0,
						style: {
							background: '#ecaf0d',
							borderRadius: '50px',
							width: (99 * 0.012) + 'rem',
							height: '2vh'
						}
					},
					{
						name: '第三小组',
						number: 95.0,
						style: {
							background: '#68ec50',
							borderRadius: '50px',
							width: (95 * 0.012) + 'rem',
							height: '2vh'
						}
					},
					{
						name: '第四小组',
						number: 89.8,
						style: {
							background: '#1c7b3b',
							borderRadius: '50px',
							width: (89.8 * 0.012) + 'rem',
							height: '2vh'
						}
					},
					{
						name: '第五小组',
						number: 82.0,
						style: {
							background: '#1e6adf',
							borderRadius: '50px',
							width: (82 * 0.012) + 'rem',
							height: '2vh'
						}
					}
				],
				options: [{
					value: '选项1',
					label: '第一小组'
				}, {
					value: '选项2',
					label: '第二小组'
				}, {
					value: '选项3',
					label: '第三小组'
				}, {
					value: '选项4',
					label: '第四小组'
				}, {
					value: '选项5',
					label: '第五小组'
				}],
				selectvalue:''
			}
		},
		mounted() {
			this.echart1()
			this.echart2()
			this.echart3()
			this.echart4()
			this.echart5()
		},
		methods: {
			echart1() {
				var myChart = echarts.init(document.getElementById('main'))
				var option = {
					title: {
						text: ''
					},
					legend: {
						show: false,
						right: '-0%',
						orient: 'vertical',
						top: '50%',
						data: ['提交量', '驳回量']
						
					},

					color: ['#1e6adf', '#ff1600'],
					radar: {
						// shape: 'circle',
						indicator: [{
								name: '交通',
								max: 230
							},
							{
								name: '城建',
								max: 230
							},
							{
								name: '经济',
								max: 230
							},
							{
								name: '民政',
								max: 230
							},
							{
								name: '环境',
								max: 230
							},
							{
								name: '教科文卫',
								max: 230
							}
						]
					},
					series: [{
						name: 'Budget vs spending',
						type: 'radar',
						data: [
							// {
							// 	value: [98, 121, 63, 79, 98, 76],
							// 	name: '提交量',
							// 	label: {
							// 		show: true,
							// 		formatter: function(params) {
							// 			return params.value;
							// 		}
							// 	}
							// },
							{
								value: [200, 158, 186, 190, 230, 180],
								name: '驳回量',
								label: {
									show: true,
									formatter: function(params) {
										return params.value;
									}
								}
							}
						]
					}]
				}
				myChart.setOption(option)
			},

			echart2() {
				var myChart = echarts.init(document.getElementById('main2'))
				var option = {
					xAxis: {
						type: 'category',
						data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
						axisLabel:{
							textStyle:{
									color:"#fff"
								},
						}
					},
					yAxis: {
						type: 'value',
						min: 0,
						max: 120,
						interval: 20,
						axisLabel: {
							textStyle:{
									color:"#fff"
								},
							formatter: '{value}'
						}
					},
					legend: {
						data: ['委员提交量', '政协办公室采纳量'],
						textStyle:{
								color:"#fff"
							},
					},
					tooltip: {
						valueFormatter: function(value) {
							return value + '个';
						}
					},
					grid: {
						top: '15%',
						bottom: '15%',
						left: '10%',
						right: '10%'
					},
					color: ['#1e6adf', '#0aeada'],
					series: [{
							name: '委员提交量',
							data: [55, 87, 37, 90, 96, 75, 42, 64, 102, 99, 77, 23],
							type: 'line'
						},
						{
							name: '政协办公室采纳量',
							data: [21, 42, 18, 27, 19, 18, 39, 38, 35, 38, 39, 50],
							type: 'line'
						}
					]
				}
				myChart.setOption(option)
			},

			echart3() {
				var myChart = echarts.init(document.getElementById('main3'))
				var option = {
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							crossStyle: {
								color: '#999'
							}
						}
					},
					legend: {
						show: false,
						data: ['群众数', '委员数'],
						textStyle:{
								color:"#fff"
							},
					},
					grid: {
						top: '10%',
						bottom: '10%',
						left: '10%',
						right: '0%'
					},
					color: ['#ff1600'],
					xAxis: [{
						type: 'category',
						data: ['经济', '城建', '教科文卫', '交通', '民政', '环境'],
						axisLabel:{
							textStyle:{
									color:"#fff"
								},
						},
						axisPointer: {
							type: 'shadow'
						}
					}],
					yAxis: [{
						type: 'value',
						name: '',
						min: 0,
						max: 70,
						interval: 10,
						axisLabel: {
							textStyle:{
									color:"#fff"
								},
							formatter: '{value}%'
						}
					}],
					series: [{
						name: '群众数',
						type: 'bar',
						tooltip: {
							valueFormatter: function(value) {
								return value + '%';
							}
						},
						label: {
							show: true,
							position: 'top',
							formatter: '{c}%'
						},
						data: [68, 21, 58, 49, 41, 50]
					}]
				}
				myChart.setOption(option)


			},

			echart4() {
				var myChart = echarts.init(document.getElementById('main4'))
				var option = {
					tooltip: {
					    trigger: 'axis',
					    axisPointer: {
					      // Use axis to trigger tooltip
					      type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
					    }
					  },
					  legend: {
						  textStyle:{
						  		color:"#fff"
						  	},
					  },
					  grid: {
					    left: '3%',
					    right: '4%',
					    bottom: '3%',
						top:'15%',
					    containLabel: true
					  },
					  xAxis: {
					    type: 'value',
						axisLabel:{
							textStyle:{
									color:"#fff"
								},
						}
					  },
					  yAxis: {
					    type: 'category',
					    data: ['张三','李四','王五','赵六','唐七','吴八','陈九'],
						axisLabel:{
							textStyle:{
									color:"#fff"
								},
						}
					  },
					  color: ['#1a8207','#68bbc4'],
					  series: [
					    {
					      name: '采纳量',
					      type: 'bar',
					      stack: 'total',
					      label: {
					        show: true
					      },
					      emphasis: {
					        focus: 'series'
					      },
					      data: [30,83,86,83,81,78,69]
					    },
					    {
					      name: '驳回量',
					      type: 'bar',
					      stack: 'total',
					      label: {
					        show: true
					      },
					      emphasis: {
					        focus: 'series'
					      },
					      data: [50,49,9,7,8,14,12]
					    }
					  ]
				}
				myChart.setOption(option)


			},
			echart5() {
				var myChart = echarts.init(document.getElementById('main5'))
				var option = {
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							crossStyle: {
								color: '#999'
							}
						}
					},
					legend: {
						data: ['经济', '城建', '教科文卫', '交通', '民政', '环境'],
						textStyle:{
								color:"#fff"
							},
					},
					grid: {
						top: '15%',
						bottom: '20%',
						left: '10%',
						right: '0%'
					},
					color: ['#ff1600', '#68bbc4','#900edb','#f2bd42','#1c7b3b','#0b5af5'],
					xAxis: [{
						type: 'category',
						data: ['第一小组', '第二小组', '第三小组', '第四小组', '第五小组'],
						axisLabel:{
							textStyle:{
									color:"#fff"
								},
						},
						axisPointer: {
							type: 'shadow'
						}
					}],
					yAxis: [{
						type: 'value',
						name: '',
						min: 0,
						max: 10,
						interval: 2,
						axisLabel: {
							textStyle:{
									color:"#fff"
								},
							formatter: '{value}'
						}
					}],
					series: [{
							name: '经济',
							type: 'bar',
							tooltip: {
								valueFormatter: function(value) {
									return value + '个';
								}
							},
							label: {
							        show: true,
							        position: 'top'
							      },
							data: [4,4.5,6,8,7]
						},
						{
							name: '城建',
							type: 'bar',
							label: {
							        show: true,
							        position: 'top'
							      },
							tooltip: {
								valueFormatter: function(value) {
									return value + '个';
								}
							},
							data: [8,6,3,9,2]
						},
						{
							name: '教科文卫',
							type: 'bar',
							label: {
							        show: true,
							        position: 'top'
							      },
							tooltip: {
								valueFormatter: function(value) {
									return value + '个';
								}
							},
							data: [5,6,7,4,6]
						},
						{
							name: '交通',
							type: 'bar',
							label: {
							        show: true,
							        position: 'top'
							      },
							tooltip: {
								valueFormatter: function(value) {
									return value + '个';
								}
							},
							data: [2,3,4,9,6]
						},
						{
							name: '民政',
							type: 'bar',
							label: {
							        show: true,
							        position: 'top'
							      },
							tooltip: {
								valueFormatter: function(value) {
									return value + '个';
								}
							},
							data: [8,4,6,4,4]
						},
						{
							name: '环境',
							type: 'bar',
							label: {
							        show: true,
							        position: 'top'
							      },
							tooltip: {
								valueFormatter: function(value) {
									return value + '个';
								}
							},
							data: [8,8,4,2,7]
						}
					]
				}
				myChart.setOption(option)


			},
		},

	}
</script>

<style lang="less" scoped>
	.box_paiming {
		width: 94%;
		display: flex;
		align-items: center;
		flex-direction: column;
		margin: auto;

		.item_paim {
			display: flex;
			align-items: center;
			font-size: 0.1rem;
			width: 100%;
			padding: 5px;
			border-bottom: 1px solid #c0c0c0;

			.coulm:nth-child(1) {
				flex: 1.5;
			}

			.coulm:nth-child(2) {
				flex: 3;
			}

			.coulm:nth-child(3) {
				flex: 6;
			}
		}

		.header_item {
			border-bottom: 2px solid #c0c0c0;
			padding: 5px 0;
		}
	}

	.datatitle {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.datatitle_name {
			width: 1.2rem;
			height: 12vh;
			border: 1px solid #c0c0c0;
			border-radius: 10px;
			box-shadow: 0 0 5px #c0c0c0;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.datatitle_item {
			width: 1.2rem;
			height: 12vh;
			border: 1px solid #c0c0c0;
			border-radius: 10px;
			box-shadow: 0 0 5px #c0c0c0;
			font-size: 0.1rem;
			padding: 0.05rem;

			.jdt {
				width: 100%;
				height: 2vh;
				border-radius: 50px;
				background-color: #e0e3ea;
				position: relative;

				div {
					position: absolute;
					left: 0;
					top: 0;
					width: 70%;
					height: 100%;
					border-radius: 50px;
				}
			}
		}
	}

	.home {
		.title_box {
			display: flex;
			align-items: center;
			width: 100%;
			padding: 10px 0.1rem;
			font-size: 0.1rem;

			.title {
				width: fit-content;
				color: rgb(11, 90, 245);
				border: 1px solid rgb(11, 90, 245);
				padding: 4px 0.2rem;
				border-radius: 5px;
				margin-right: 0.2rem;
			}
		}

		.selectmouth {
			display: flex;
			align-items: center;
			width: 0.8rem;

			div {
				flex: 1;
				text-align: center;
				padding: 3px 0;
				cursor: pointer;
			}

			div:nth-child(1) {
				border-top: 1px solid #c0c0c0;
				border-bottom: 1px solid #c0c0c0;
				border-right: 1px solid #c0c0c0;
				border-left: 1px solid #c0c0c0;
				color: #fff;
			}

			div:nth-last-child(1) {
				border: 1px solid rgb(11, 90, 245);
				color: rgb(11, 90, 245);
			}
		}

		.block {
			margin-left: 0.1rem;
			width: 0.65rem;

			/deep/.el-date-editor.el-input {
				width: 100%;
			}
			/deep/.el-select{
				height: 100%;
			}
		}

		.diyipai {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 32vh;
			margin-top: 2vh;
			margin-bottom: 2vh;

			.item {
				width: 32%;
				height: 100%;
				border: 1px solid #c0c0c0;
				border-radius: 10px;
				box-shadow: 0 0 5px #c0c0c0;



			}
		}

		.dierpai {
			height: 32vh;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.item {
				width: 32%;
				height: 100%;
				border: 1px solid #c0c0c0;
				border-radius: 10px;
				box-shadow: 0 0 5px #c0c0c0;
			}
		}
	}
	/deep/ .block{
		input{
			background: none !important;
		}
	}
</style>
